
/*# ProForms #*/

/*# ProForms / Body #*/
body.proman-forms {
    background-color: #FCF2F0;
}



.responsive-square {
    position: relative;
    width: 100%;
    pointer-events: none;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
        pointer-events: none;
    }

    .inr {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

.white-bkg {
    background-color: #fff;
}




/*# ProForms / Wrapper/Layout #*/

.pf-wrp {  
    box-shadow: 0 0 11px rgba(0,0,0,.12);
    background-color: #fff;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .checkbox input[type="checkbox"] {
        margin-left: -20px;
    }

    .shape-widget {
        margin-top: -2px;
        padding-top: 17px;
    }

    .pf-header {
        background-color: #003680;
        color: #fff;
        padding: 17px 101px;

        .row {
            @media (min-width: 767px) {
            display: flex;
            align-items: center;
            }
        }

        @media screen and (max-width: 1600px) {
            padding: 20px 97px;
        }

        @media screen and (max-width: 1200px) {
            padding: 17px 81px;
        }

        @media screen and (max-width: 992px) {
            padding: 20px 62px;
        }

        @media screen and (max-width: 767px) {
            padding: 20px 48px;
        }

        .logo {
            width: 100%;   
            max-height: 166px;
    
            @media screen and (max-width: 1200px) {
                max-height: 108px;
            }

            @media screen and (max-width: 767px) {
                max-height: 109px;
                margin-bottom: 20px;
            }

            .inr {
                text-align: center;

                .helper {
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }

                img {
                    display: inline-block;
                    height: auto;
                    max-width: 85%;
                    vertical-align: middle;
                    max-height: 120px;
                }
            }
        }

        h1 {
            color: #fff;
            font-size: 36px;
            margin-bottom: 0;
            text-transform: capitalize;

            @media(max-width: 767px) {
                text-align: center;
            }
        }

        .intro {
            margin-top: 20px;
            display: none;


            * {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .pf-content {
        padding: 75px 101px 30px;

        @media screen and (max-width: 1600px) {
            padding: 75px 97px 30px;
        }

        @media screen and (max-width: 1200px) {
            padding: 75px 81px 30px;
        }

        @media screen and (max-width: 992px) {
            padding: 75px 63px 30px;
        }

        @media screen and (max-width: 767px) {
            padding: 75px 48px 30px; 
        }

        .question {

            .q-top {

                .q-number {
                    min-width: 30px;
                    display: inline-block;
                    font-size: 20px;
                    text-align: center;
                    background-color: #ED6A14;
                    color: #fff;
                    display: none;
                }

                .q-question {
                    margin-top: 31px;
                    margin-bottom: 7px;
                    font-size: 14px;
                    

                    @media screen and (max-width: 767px) {
                        margin-top: 37px;
                        margin-bottom: 11px;
                    }

                    * {
                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            #q-answer {
                opacity: 1;
                text-transform: uppercase;
                font-size: 12px;
                width: 100%;
                margin-top: 10px;

                label {
                    min-height: 20px;
                    input {
                        border: none;
                        height: auto;
                    }
                }

                input {
                    border: 1px solid #133b89;
                    height: 38px;
                }

                &.active {
                    border: 3px solid #A6CBF3;
                    border-radius: 5px;
                    width: 67%;

                    @media screen and (max-width: 1600px) {
                        width: 81%;
                    }

                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }

                &.read-only {
                    background-color: #EAECEF;
                    border: 1px solid #CED4DA;
                    border-radius: 5px;
                    width: 67%;

                    @media screen and (max-width: 1600px) {
                        width: 81%;
                    }

                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }
                }

                &.success {
                    border: 1px solid #28A745;
                    border-radius: 5px;
                    width: 67%;

                    @media screen and (max-width: 1600px) {
                        width: 81%;
                    }

                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }
                }

                &.error {
                    border: 1px solid #DC3545;
                    border-radius: 5px;
                    width: 67%;

                    @media screen and (max-width: 1600px) {
                        width: 81%;
                    }

                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }
                }

                * {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

    }

    .form input {
        opacity: 1;
        text-transform: uppercase;
        font-size: 12px;
        width: 100%;
        border: 1px solid #133b89;
        height: 38px;
        border-radius: 0;
        height: 30px;
    }
    .form textarea {
        font-size: 12px;
        border: 1px solid #133b89;
        border-radius: 0;
    }

    .form label, .error-label {
        font-weight: normal;
        font-size: 14px;
    }

    .error-label {
        margin-top: 5px;
        color: red;
    }

    .pf-footer {
        padding: 0px 101px 72px 101px;

        @media screen and (max-width: 1600px) {
            padding: 0px 97px 82px 98px;
        }

        @media screen and (max-width: 1200px) {
            padding: 0px 81px 72px 81px;
        }

        @media screen and (max-width: 992px) {
            padding: 0px 63px 56px 62px;
        }

        @media screen and (max-width: 767px) {
            padding: 0px 48px 37px 48px;
        }
    }

    .btn {
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 14px;
        height: 31px;
        text-align: center;
        letter-spacing: 0px;
        background-color: #fff;
        color: #0C2557;
        border: 1px solid #0C2557;
        opacity: 1;
    }

    .video-trigger {
        position: fixed;
        right: 0;
        top: 50%;
        background-color: #003680;
        color: #fff;
        display: inline-block;
        padding: 10px;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
        transform-origin: 100% 100%;
        transform: rotate(270deg) translateX(50%);
        border-radius: 10px 10px 0 0;
    }    


}





.office-form-theme-primary-color {
    color: #CE4B28;
}

.office-form-theme-secondary-color {
    color: #BC2E1B;
}

.office-form-theme-primary-background {
    background: #CE4B28;
    color: #FFFFFF;
}

.office-form-theme-secondary-background {
    background: #BC2E1B;
}

.office-form-theme-primary-foreground, .office-form-theme-primary-foreground a, .office-form-theme-primary-foreground.rateit .rateit-selected, .office-form-theme-primary-foreground.rateit .rateit-hover {
    color: #CE4B28;
}

.office-form-theme-border-top {
    border-top-color: #CE4B28;
}

.office-form-theme-border-bottom-for-mobile {
    border-bottom-color: #CE4B28;
}

.office-form-theme-spinner {
    border-color: #CE4B28 #C7E0F4 #C7E0F4;
}

.office-form-theme-border {
    border-color: #CE4B28;
}

.office-form-theme-interactive-background.active {
    background-color: #CE4B28;
}

.office-form-theme-interactive-background:hover {
    background-color: #BC2E1B;
}

.office-form-theme-interactive-background:focus {
    background-color: #BC2E1B;
}

.office-form-theme-interactive-background:active {
    background-color: #BC2E1B;
}

.office-form-theme-button-lightbackground {
    color: #CE4B28;
}

.office-form-theme-button-lightbackground.active {
    background-color: #BC2E1B;
}

.office-form-theme-interactive-color {
    color: #CE4B28;
}

.office-form-theme-interactive-color:hover {
    color: #BC2E1B;
}

.office-form-theme-interactive-color:focus {
    color: #BC2E1B;
}

.office-form-theme-interactive-color:active {
    color: #BC2E1B;
}

.office-form-theme-button {
    color: white;
    background: #CE4B28;
}

.office-form-theme-button:hover {
    background: #BC2E1B;
}

.office-form-theme-button:focus {
    background: #BC2E1B;
}

.office-form-dark-theme-button {
    color: #BC2E1B
}

.office-form-dark-theme-button:hover {
    color: #BC2E1B;
}

.office-form-dark-theme-button:focus {
    color: #BC2E1B;
}

.office-form-theme-page-background-active.active {
    background: #CE4B28;
    color: #FFFFFF;
}

.office-form-theme-page-foreground-active.active, .office-form-theme-select-color.active * {
    color: #CE4B28;
}

.office-form-theme-toggle.active {
    background: #CE4B28;
    border-color: #CE4B28;
}

.office-form-theme-border {
    border-color: #CE4B28;
}

.office-form-theme-outline {
    outline-color: #CE4B28;
}

.office-form-theme-button-foreground.active, .office-form-theme-button-foreground.touch.active:hover {
    color: white;
    background: #CE4B28;
    outline-color: #CE4B28;
}

.office-form-theme-button-foreground.active:hover {
    background: #BC2E1B;
    outline-color: #BC2E1B;
}

.office-form-theme-quiz-point {
    color: #BC2E1B;
}

.office-form-theme-quiz-totalPoints-bar {
    color: #CE4B28;
}

.office-form-theme-image-question-background, .office-form-theme-video-question-background {
    background-color: #FCF2F0;
    outline: 1px solid transparent;
}

.office-form-theme-image-container-border {
    border: 3px solid #FCF2F0;
}

.office-form-sortable-chosen .office-form-sort-item {
    background: #CE4B28;
    color: white;
}

.office-form-theme-sort-item {
    color: white;
    background: #CE4B28;
}

.office-form-theme-sort-item:hover {
    background: #BC2E1B;
}

.office-form-theme-focus-border .office-form-textfield-input:focus, .office-form-theme-focus-border .form-control:focus, .office-form-theme-focus-border .select-control:focus, .office-form-theme-focus-border .picker--focused .picker__holder:focus {
    border-color: #CE4B28;
    outline-color: #CE4B28;
}

.office-form-theme-bgcolor-hover:hover {
    background: #FCF2F0;
}

.office-form-theme-button-color:hover * {
    color: #BC2E1B;
}

.form-intelligence-question-row.office-form-intelligence-theme-li {
    outline: 1px solid #BC2E1B;
    background-color: #FCF2F0;
}

.form-intelligence-question-row.mobile-office-form-intelligence-theme-li {
    outline: 1px solid #CE4B28;
    background-color: #FCF2F0;
    color: #CE4B28;
}

.office-form-insight-theme-control .simple-data-control-content {
    color: #CE4B28;
}

.office-form-insight-theme-control .simple-data-control-description {
    color: #CE4B28;
}

.office-form-insight-theme-control .simple-data-control-description:hover {
    color: #BC2E1B;
}

.office-form-insight-theme-control .simple-data-control-description:focus {
    color: #BC2E1B;
}

.office-form-insight-theme-control .simple-data-control-description:active {
    color: #BC2E1B;
}

.office-form-info-container .office-form-info-children.office-form-theme-spo-link {
    color: #CE4B28;
}

.office-form-info-container .office-form-info-children.office-form-theme-spo-link:hover {
    color: #BC2E1B;
}

.office-form-spo-embed .office-form-footer-block .office-form-theme-spo-link {
    color: #CE4B28;
}

.office-form-spo-embed .office-form-footer-block .office-form-theme-spo-link:hover {
    color: #BC2E1B;
}

.office-form-theme-spo-link {
    color: #CE4B28;
}

.office-form-theme-spo-link:hover {
    color: #BC2E1B;
}

.office-form-theme-footer {
    background: rgba(255,255,255,.4);
}

.office-form-theme-page-background {
    background-color: #FCF2F0;
}

.office-form-theme-shadow {
    box-shadow: 0 0 11px rgba(0,0,0,.12);
}



// Modal styles
.ReactModal__Overlay {
    background-color: #00000080 !important;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0.8) translate(-50%, -50%);

    transition: all 200ms ease-in-out;
    border: none !important;
    background: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    max-width: 100%;
    width: 600px;
    padding: 24px !important;
  
    &:focus {
      outline: none;
    }
  }
  
  .ReactModal__Content--after-open {
    transform: scale(1) translate(-50%, -50%);
  }
  
  .ReactModal__Content--before-close {
    transform: scale(0.8) translate(-50%, -50%);
  }
  
  .modal-close-btn {
    position: absolute;
    top: 6px;
    right: 16px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #666;
    transition: color 0.2s ease;
  
    &:hover {
      color: #333;
    }
  }
  
  .modal-content {
    margin-top: 16px;
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .ReactModal__Content {
      width: 90%;
      padding: 16px !important;
    }
  
    .modal-close-btn {
      top: 8px;
      right: 8px;
      font-size: 20px;
    }
  }